import '../../styles/homeandlifepage.css';

import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Illustration from '../../images/illustration-bubble-tip.png';
import girlwithcat from '../../images/images/group-3.png';
import PH_Image from '../../images/images/group-8.png';
import righttick from '../../images/images/icon-tick-green.svg';
import bitmap_1 from '../../images/images/ratingOne.png';
import bitmap_3 from '../../images/images/ratingThree.png';
import bitmap_2 from '../../images/images/ratingTwo.png';
import nationwidelogo from '../../images/nationwidelogo.png';

const NationwideAutoPage = ( { data, parent } ) => {

  return (
    <Layout header={'menu'}>
      <SEO
        title="Auto insurance by Nationwide, Nationwide, Best Life Insurance Policy, Life insurance quotes online, Online insurance"
        canonicalUrl='https://www.getmybubble.com/nationwideauto/'
        description="Auto insurance by Nationwide, Nationwide, Auto insurance quotes online, Online  home insurance policy"
        keywords="Auto insurance by Nationwide, Nationwide, Auto insurance quotes online, Online  home insurance policy"
      />

      {/* <Menu /> */}

      <div className="flexColumn carrierCommonPage">
        <div className="container-fluid carrier-plan-container_1">
          {/* carrier-plan-container */}

          <div className="container">
            <div className="row">
              <div className="col-lg-6 ">
                <div className="padding-left">
                  <img src={nationwidelogo} alt="logo" width="200"/>

                  <h1 className="carrier-plan-heading">Auto insurance</h1>

                  <p className="carrier-plan-section1-para">
                    Nationwide car insurance can cover you for accidents involving other vehicles, vandalism, weather, animals, bodily injuries and more. Our policies are customizable – you can choose the auto insurance policies that suit your lifestyle.
                  </p>

                  <div className="flexRow align-btns ml-1">
                    <Link to="/">
                      <button type="button" className="orangeBtn mr-3">
                      Get a quote now
                      </button>
                    </Link>

                    <a href="#start-exploring" style={{ color: '#ff7007', textDecoration: 'none' }}>
                      <button type="button" className="explore">
                        Explore
                      </button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 sbli-image" style={{ textAlign: 'center' }}>
                <img src="https://cdn.getmybubble.com/images/corporate-sites/auto-insurance.svg" className="carrier-right-img-mv mt-4 mt-sm-4 mt-md-4 mt-lg-0 auto-image" alt="" />
              </div>
            </div>
          </div>

        </div>

        {/*section 2*/}

        <div id="start-exploring" className="container-fluid" style={{ marginTop: '80px' }}>
          <div className="row">
            <div className="col-lg-3"></div>

            <div className="col-lg-6 costom-padding19">
              <h3 className="stillwater-title1">
                Why to choose Nationwide's Auto Insurance?
              </h3>

              <p className="stillwater-para1">
                Auto insurance from Nationwide gives you peace of mind. When you choose an On Your Side Auto Repair Network® facility, you can get a written guarantee on your car repairs. With their On Your Side Claims® Service, you also get personal service like the On Your Side® Review, an annual check-in with your insurance professional to review your coverage. And with multi-policy and other discounts, such as the SmartRide® and SmartMiles® usage-based programs, you get peace of mind and value.
              </p>

            </div>

            <div className="col-lg-3"></div>
          </div>
        </div>

        {/* Product Highlights Section */}

        <div className="container-fluid">
          <div className="row" style={{ marginTop: '30px' }}>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-2"></div>

                <div className="col-lg-9 product-container-alignment costom-padding19">
                  <div className="stillwater-product-container">

                    <div className="align-horizontal-center">
                      <img src={PH_Image} className="ph-desktop-view" alt="phdesk"/>

                      <h3
                        className="ph-title ph-desktop-view">
                        Ways to save on auto insurance
                      </h3>

                      <h3
                        className="ph-title ph-mobile-view">
                        Ways to save on auto insurance
                      </h3>

                      <img src={PH_Image} className="ph-mobile-view" alt="phmob"/>
                    </div>

                    <div
                      style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} className="img-responsive" alt="tick" style={{ verticalAlign: 'middle' }}></img>

                      <div className="ph-item-style pl-3">
                        Nationwide offers a range of car insurance discounts that can help lower your monthly premiums - learn more about each discount type and start saving today.
                      </div>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        Multi-policy
                        Save an average of $646 when you bundle home and car insurance with Nationwide.
                      </div>

                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        Claims-free
                        You may qualify for a lower premium on your home insurance when you stay claims-free.
                      </div></div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        SmartRide
                        Plug in, drive and start saving today. Earn a discount by joining our usage based insurance program that gives you feedback to make safer driving decisions.
                      </div></div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        SmartMiles
                        Drive less, Pay less. Get the same great coverage as a traditional Nationwide auto policy, but with a flexible monthly rate that's based on how many miles you drive.
                      </div></div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        Accident free
                        Get a discount by maintaining an accident-free driving record.
                      </div></div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        Good student
                        Drivers age 16 to 24 who maintain a B average or better are eligible for a discount.
                      </div></div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                        Defensive driving
                        Complete a state-approved safety course and get a discount. (You may have to be 55 or over to be eligible.)
                      </div></div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                      Anti-theft
Discount applies if your vehicle is equipped with certain anti-theft devices. Proof of installed device may be required.
                      </div></div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                      Safe Driver
Nationwide rewards customers who are free of at-fault accidents and major violations. If you have at least 5 years of safe driving, you could be eligible for a more affordable car insurance rate.

                      </div></div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} alt="tick"/>

                      <div className="ph-item-style pl-3">
                      Automatic payments
Set up automatic deductions from your bank account and get a one-time discount.
                      </div></div>

                  </div>

                </div>

                <div className="col-lg-1"></div>
              </div>
            </div>

            <div className="col-lg-6 carrier-middle-container carrier-middle-container-mv">
              <h4 className="stillwater-col2-title">Standard Coverages:</h4>

              <p>These coverages help protect you, your passengers, or other people on the road in the event of an accident.</p>

              <ul className="stillwater-list stillwater-list-item stillwater-list-mv common-width">
                <li>
                  <b>Bodily injury liability</b>

                  <p>If you cause an accident and someone is injured, this coverage can help pay their medical bills and loss of income.</p>
                </li>

                <li>
                  <b>Property damage liability</b>

                  <p>If you cause an accident, this coverage helps pay for damages to another person's vehicle or property.</p>
                </li>

                <li>
                  <b>Personal injury protection</b>

                  <p>Learn about PIP coverage and how it may keep you covered in the event of an accident.</p>
                </li>

                <li>
                  <b>Uninsured motorist</b>

                  <p>Helps protect you and your vehicle from uninsured drivers and hit-and-run accidents.</p>
                </li>

                <li>
                  <b>Underinsured motorist</b>

                  <p>Helps protect you from drivers who do not have enough insurance to cover the costs of an accident.</p>
                </li>

                <li>
                  <b>Medical payments</b>

                  <p>Also known as med pay, helps pay medical costs for you and your passengers in the event of a covered auto accident.</p>
                </li>

                <li>
                  <b>Vehicle coverages</b>

                  <p>These coverages help you with repairing any damage done to your vehicle or another driver’s, as well as providing additional resources if you can no longer use your own car.</p>
                </li>

                <li>
                  <b>Collision</b>

                  <p>Regardless of who’s at fault, collision can pay for damage to your vehicle if you hit another vehicle or object, another vehicle hits you, or your vehicle rolls over.</p>
                </li>

                <li>
                  <b>Comprehensive</b>

                  <p>Also known as “other than collision,” this coverage can help pay for damage to your vehicle from vandalism, theft, weather events and accidents involving animals.</p>
                </li>

                <li>
                  <b>Towing and labor coverage</b>

                  <p>This coverage can help when your car is stuck, broken down or inoperable due to an accident. (If collision coverage is not available.)</p>
                </li>

                <li>
                  <b>Rental car expense</b>

                  <p>This coverage provides access to a rental car or other transportation while your car is in the repair shop after a covered accident. Also known as loss of use or transportation expenses.</p>
                </li>

                <li>
                  <b>Gap coverage</b>

                  <p>If your car is a total loss after an accident, this coverage may pay the difference between the actual cash value and what you owe on the lease or loan.</p>
                </li>

              </ul>

              <h4 className="stillwater-col2-title">Optional Coverages:</h4>

              <ul className="stillwater-list stillwater-list-item stillwater-list-mv common-width">
                <li>
                  <b>Special auto coverages</b>

                  <p>You may find these optional features beneficial to add onto your car insurance policy:</p>
                </li>

                <li>
                  <b>Accident Forgiveness®</b>

                  <p>Avoid a rate increase because of the first at-fault accident or minor violation on your policy.</p>
                </li>

                <li>
                  <b>Roadside Assistance</b>

                  <p>Get 24/7 emergency help if you need fuel, a tire change, a jump-start or a tow.</p>
                </li>

                <li>
                  <b>Vanishing Deductible®</b>

                  <p>Earn $100 off your deductible for each year of safe driving, up to $500. Your deductible could vanish completely.</p>
                </li>

                <li>
                  <b>Total loss deductible waiver</b>

                  <p>Have the physical damage deductible (comprehensive or collision) waived in the event of a total loss.</p>
                </li>


              </ul>


            </div>
          </div>
        </div>

        <div className="container stillwater-card-container">
          <div className="row">
            <div className="col-lg-2">
            </div>

            <div className="col-lg-8">
              <h3 className="stillwater-title1">When it comes to protecting your assets, we make the grade.</h3>

              <div className="card-deck align-stillwater-cards">

                <div className="row">
                  <div className="col-md-4 col-lg-4 section30 costom-padding19">
                    <div className="card stillwater-cards">
                      <div className="card-header align-stillwater-card-header">
                        <img src={bitmap_1} alt="bitmap" className="image-grades"/>
                      </div>

                      <div className="card-body bg-card-body">
                        <p className="stillwater-card-text">
                          received 10/17/2002<br/>
                          affirmed 12/22/21</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 section30 costom-padding19">
                    <div className="card stillwater-cards">
                      <div className="card-header align-stillwater-card-header">
                        <img src={bitmap_2} alt="bitmap" className="image-grades"/>
                      </div>

                      <div className="card-body bg-card-body">
                        <p className="stillwater-card-text">received 12/22/2008
                          <br/>affirmed 5/7/21</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 col-lg-4 section30 costom-padding19">
                    <div className="card stillwater-cards">
                      <div className="card-header align-stillwater-card-header">
                        <img src={bitmap_3} alt="bitmap" className="image-grades"/>
                      </div>

                      <div className="card-body bg-card-body">
                        <p className="stillwater-card-text">received 3/10/2009
                          <br/>affirmed 5/27/2020</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-2">
          </div>
        </div>

        <div className="plansForYou">
          <div className="orangeBackGround">
            <div>
              Find out which plan is right for you
            </div>

            <div className="customizedPlan">
              Policies are customized according to your needs.
              We have simplified the process to help you find the right policy that fits your budget and loved ones.
            </div>

            <Link to="/" type="button" className="btnWhite">
              Get your quote now
            </Link>
          </div>
        </div>
      </div>
    </Layout> );
};

export default NationwideAutoPage;
